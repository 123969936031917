// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-dalia-jsx": () => import("./../../../src/pages/dalia.jsx" /* webpackChunkName: "component---src-pages-dalia-jsx" */),
  "component---src-pages-frontier-jsx": () => import("./../../../src/pages/frontier.jsx" /* webpackChunkName: "component---src-pages-frontier-jsx" */),
  "component---src-pages-giveaway-jsx": () => import("./../../../src/pages/giveaway.jsx" /* webpackChunkName: "component---src-pages-giveaway-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kiragrace-jsx": () => import("./../../../src/pages/kiragrace.jsx" /* webpackChunkName: "component---src-pages-kiragrace-jsx" */),
  "component---src-pages-kompany-39-jsx": () => import("./../../../src/pages/kompany39.jsx" /* webpackChunkName: "component---src-pages-kompany-39-jsx" */),
  "component---src-pages-mossynissan-jsx": () => import("./../../../src/pages/mossynissan.jsx" /* webpackChunkName: "component---src-pages-mossynissan-jsx" */),
  "component---src-pages-nativ-jsx": () => import("./../../../src/pages/nativ.jsx" /* webpackChunkName: "component---src-pages-nativ-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-referral-jsx": () => import("./../../../src/pages/referral.jsx" /* webpackChunkName: "component---src-pages-referral-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

